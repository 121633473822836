<!-- ########################## <Template> ########################## -->
<template lang="">
    
    <Header></Header>
    <div class="pageTitle" > 
        <font-awesome-icon icon="caret-right" /> 
        Nouveau Scoring
    </div>

    <!--  -->
    <Toast />
    
    <!--  -->
    <div class="pageBodyContainer">
        <LoadPdfScore ref="LoadPdfScore"></LoadPdfScore>    
        <div class="divPanel" style="position:relative" >
            
            <div :class="'topRightCorner'">
 
            </div>

            <div class="row">
                <div class="col-2 titre">
                ICE
                </div>
                <div class="col-4 ">
                {{selectedSociete.ice?selectedSociete.ice:""}}
                </div>
                <div class="col-2 titre">
                RC
                </div>
                <div class="col-4">
                {{selectedSociete.rc?selectedSociete.rc:""}}
            </div>
               
            </div>
            <div class="row">
                <div class="col-2 titre">
                Raison sociale
                </div>
                <div class="col-4">
                {{selectedSociete.raisonSociale?selectedSociete.raisonSociale:""}}
                </div>
                <div class="col-2 titre">
                Ville
                </div>
                <div class="col-4">
                {{selectedSociete.ville && selectedSociete.ville.nomVille?selectedSociete.ville.nomVille:""}}
                </div>
            </div>
            
            <hr class="my-12" style="color:rgb(61, 87, 216)"/>

            <div  style="position:relative">
                <div :class="'topRightCorner'">
                    <kendo-button icon="graph" @click="openHistoriqueScoreDialog()" style="width:160px" :disabled="!dernierHistoriqueScore.dateScore" >Historique scores</kendo-button>
                </div>
            </div>
        <!-- </div>

        <div class="divPanel" > -->
            <div class="row">
                <div class="col-2 titre">
                    Dernier score                          
                </div>
                <div class="col-4">
                    {{dernierHistoriqueScore.score?dernierHistoriqueScore.score+"/10":""}}
                </div>
                <div class="col-2 titre">
                    Analyste                          
                </div>
                <div class="col-4">
                     {{dernierHistoriqueScore.utilisateur?dernierHistoriqueScore.utilisateur:""}}
                </div>
                
            </div>
            <div class="row">
                <div class="col-2 titre">
                    Date
                </div>
                <div class="col-4">
                    {{dernierHistoriqueScore.dateScore?this.formatDate(dernierHistoriqueScore.dateScore):""}}
                </div>
                <div class="col-2 titre">
                    Date validité                          
                </div>
                <div class="col-4">
                    {{dernierHistoriqueScore.dateValidite?this.formatDate(dernierHistoriqueScore.dateValidite):""}}
                    &nbsp;<kendo-button v-if="dernierHistoriqueScore.dateValidite" icon="edit"  @click="this.flagOpenModifyDateValidateWithoutPublish = true"></kendo-button>
                </div>
            </div>

            <hr class="my-12" style="color:rgb(61, 87, 216)"/>

            <div class="row p-grid p-align-center">
                <div class="col-2 titre">
                    CA escompté
                </div>
                <div class="col-2">
                    <InputNumber :disabled="(historiqueScoreActuel && historiqueScoreActuel.etatPublication)" id="locale-us" v-model="caEscompte" mode="decimal" locale="ar-MA" :minFractionDigits="0"  />
                </div>
                <div class="col-2 titre">
                    Délai paiement (jours)               
                </div>
                <div class="col-2">
                    <InputNumber :disabled="(historiqueScoreActuel && historiqueScoreActuel.etatPublication)" id="locale-us" v-model="delaiPaiement" mode="decimal" :useGrouping="false"  />
                </div>
                <div class="col-2 titre" >
                    Limite crédit calculée 
                    <br/>
                 </div>
                <div class="col-2" style="position: relative;" >
                    <InputNumber :disabled="true" id="locale-us" v-model="LimiteCreditCalculee" mode="decimal" locale="ar-MA" :minFractionDigits="0" style="width: 200px;" /> 
                    <br/>
                    <br/>
                  </div>
                
                <hr class="my-12" style="color:rgb(61, 87, 216)"/>
            </div>


            <div  style="position:relative">
                <div :class="'topRightCorner'" style="top: -4px">
                    <!-- <kendo-button icon="add" @click="openEditBilanDialog({id:0,societeId, dateBilan:new Date()},false)" :disabled="flagCreerNouveauScore">Nouveau bilan manuel</kendo-button>
                    <kendo-button icon="add" @click="openEditBilanDialog({id:0,societeId, dateBilan:new Date()},true)" :disabled="flagCreerNouveauScore">Nouveau bilan inforisk</kendo-button> -->
                    <!-- <SplitButton class='splitButtonAdd' label="Nouveau Bilan" :id="'ButtonClickedId_0'" icon="pi pi-plus" @click="openEdit()" :model="items" style="min-width:8rem;"></SplitButton> -->
                    <br>
                    
                </div>
            </div>
            
        </div>

        <!-- Edit Dialog -->
        <ClientBilanDialog :inforisk="inforisk" :key="showBilanDialogFlag" :selectedBilanItemProp="selectedBilanItem" :showBilanDialogFlag="showBilanDialogFlag" @saved="savedBilanDialog" @close="closeBilanDialog" :societe="selectedSociete"></ClientBilanDialog>
        <!-- Edit Dialog -->
        <ClientHistoriqueScoreDialog v-if="showHistoriqueScoreDialogFlag" :key="showHistoriqueScoreDialogFlag" :societeId="societeId" :showHistoriqueScoreDialogFlag="showHistoriqueScoreDialogFlag" @close="closeHistoriqueScoreDialog" @scorePdfDialog="scorePdfById" ></ClientHistoriqueScoreDialog>
        
        <div class="divPanel">
            <div class="row">
                <div class="col-6 border-right">
                    <!-- Indicateurs financiers -->
                    <div class="k-state-active tabStribHeader" style="vertical-align:middle;border-bottom-color: #f31700;border-width: 2px;">
                        <span class="k-icon k-i-dollar" style="font-size:20px; margin-top:-4px"></span>
                        Indicateurs financiers&nbsp; 
                        <!-- {{JSON.parse(currentUser.jsonUserRights).scoringFinancier}} -->
                        <span v-if="!flagCreerFinancier && !historiqueScoreActuel.etatPublication" :tooltip="currentUser.jsonUserRights.scoringFinancier?'Créer score financier':'Vous n\'avez pas les droits'">
                            <P-Button :disabled="!currentUser.jsonUserRights.scoringFinancier"  @click="GenerateCreerFinancier" icon="pi pi-plus" class="p-button p-button-primary p-button-rounded"  />
                        </span>
                        <div v-else-if="flagCreerFinancier" style="color:black;vertical-align:middle">
                            Bilans:&nbsp;
                            <div v-for="bilan in bilansArray" :key="bilan.id" class="col-2 p-field-checkbox"  style="margin-bottom:0;display: contents;">
                                <!-- h2 -->
                                <Checkbox :disabled="(!currentUser.jsonUserRights.scoringFinancier && !currentUser.jsonUserRights.validationFinanciere && !currentUser.jsonUserRights.scoringPublication) || this.historiqueScoreActuel && (this.historiqueScoreActuel.etatValidationFinanciere || this.historiqueScoreActuel.etatPublication)" :id="bilan.id" name="bilan" :value="bilan" v-model="selectedBilans" @change="onBilanCheckboxChange"/>
                                <span @click="openEditBilanDialog(bilan,null)" style="padding-left:5px" >{{bilan.dateBilan}} <span style="color: gray;font-size: 10px;">({{bilan.inforisk?"Inforisk":"Manuel"}})</span>&nbsp;<i style="cursor:pointer;" class="pi pi-eye"></i> </span>
                                &nbsp;
                            </div>
                        </div>
                        <div v-if="bilansArray.length == 0 && flagCreerFinancier" style="height:40px;color:red;font-size:13px">
                            Aucun bilan financier chargé !
                        </div>
                    </div>             
                </div>
                <div class="col-6">
                    <!-- Indicateurs qualitatifs -->
                    
                        <div class="tabStribHeader" style="vertical-align:middle;border-bottom-color: #37b400;border-width: 2px;">
                                <span class="k-icon k-i-saturation" style="font-size:20px; margin-top:-3px"></span>
                                Indicateurs qualitatifs &nbsp;
                                <span v-if="!flagCreerQualitatif && !historiqueScoreActuel.etatPublication" :tooltip="currentUser.jsonUserRights.scoringQualitatif?'Créer score qualitatif':'Vous n\'avez pas les droits'">
                                    <P-Button :disabled="!currentUser.jsonUserRights.scoringQualitatif" @click="flagCreerQualitatif = true" icon="pi pi-plus" class="p-button p-button-primary p-button-rounded" />
                                </span>
                        </div>
                    
                </div>
            </div> 
            <div class="row scoringDiv"> 
                <div class="col-6 border-right">
                    
                    <div v-if="flagCreerFinancier">
                        <ClientScoringIF1Component v-if="selectedBilans.length==2"
                                                ref="ClientScoringIF1Component" 
                                                :key="selectedBilans.length+'-'+flagBilanChanged" 
                                                :clientId="0"
                        ></ClientScoringIF1Component>
                        <span style="color:red" v-else > Veuillez sélectionner 2 bilans</span>

                        <span style="color:red" v-if="this.bilansArray.length<2" > / créer les bilans à associer</span>
                      
                        <div class="grid" v-if="selectedBilans && selectedBilans.length==2">
                            <div class="col-4 titre" >
                                <div style="margin-top:7px">Limite crédit souhaitée</div>            
                            </div>
                            <div class="col-4" style="position: relative;" >
                                <InputNumber :disabled="(historiqueScoreActuel && historiqueScoreActuel.etatPublication)" id="locale-us" v-model="limiteCreditSouhaitee" mode="decimal" locale="ar-MA" :minFractionDigits="0" style="width: 200px;margin-top:5px" />  
                            </div>
                        </div>

                        <div v-if="selectedBilans.length==2">
     
                            <P-Button v-if="historiqueScoreActuel.id && currentClient.activerWorkflowValidation && (currentUser.jsonUserRights.scoringFinancier|| currentUser.jsonUserRights.scoringPublication)" :disabled="historiqueScoreActuel.etatValidationFinanciere >= 1 || historiqueScoreActuel.etatPublication" icon="pi pi-check" label="Soumettre" class="p-button p-button-success mr-auto" style="width:150px;" @click="SoumettreFinancier()"/>
                         
                            <P-Button v-if="historiqueScoreActuel.id && currentClient.activerWorkflowValidation && (currentUser.jsonUserRights.validationFinanciere|| currentUser.jsonUserRights.scoringPublication)" :disabled="historiqueScoreActuel.etatValidationFinanciere != 1 || historiqueScoreActuel.etatPublication" icon="pi pi-check" label="Valider" class="p-button p-button-primary mr-auto" style="width:150px;padding-left:10px" @click="ValiderFinancier()" />                              
                        </div>
                    </div>
                    
                </div>
                <div class="col-6" >
                    <!-- Indicateurs qualitatifs -->
                    <div v-if="flagCreerQualitatif">
                        <ClientScoringIQ1Component
                                                
                                                ref="ClientScoringIQ1Component" 
                                                :key="historiqueScoreActuel.id" 
                                                :clientId="0" 
                        ></ClientScoringIQ1Component>
                        
                        <P-Button :disabled="historiqueScoreActuel.etatValidationQualitative >= 1 || historiqueScoreActuel.etatPublication" v-if="(currentUser.jsonUserRights.scoringQualitatif || currentUser.jsonUserRights.scoringPublication) && historiqueScoreActuel.id && currentClient.activerWorkflowValidation" icon="pi pi-check" label="Soumettre" class="p-button p-button-success mr-auto" style="width:150px;" @click="SoumettreQualitatif()"/>
                        &nbsp;
                        <P-Button :disabled="historiqueScoreActuel.etatValidationQualitative !=1 || historiqueScoreActuel.etatPublication" v-if="(currentUser.jsonUserRights.validationQualitative || currentUser.jsonUserRights.scoringPublication) && historiqueScoreActuel.id && currentClient.activerWorkflowValidation" icon="pi pi-check" label="Valider" class="p-button p-button-primary mr-auto" style="width:150px;" @click="ValiderQualitatif()"/>
                    </div>
                </div>
            </div>   
            <hr class="my-12" style="color:rgb(61, 87, 216)"/>    
        <!-- </div>

        <div class="divPanel" > -->
            <div class="row">
                <div class="col-2 titre">
                    Score financier                         
                </div>
                <div class="col-4">
                    {{formatDecimal2(noteIF)}}% ({{sommeNotesIFs}}/{{totalEchellesIFs}})
                </div>
                <div class="col-2 titre">
                    Score qualitatif                             
                </div>
                <div class="col-4">
                      <!-- * this.$refs.ClientScoringIQ1Component.listeIndicateurs.length -->
                     {{formatDecimal2(noteIQ)}}% ({{sommeNotesIQs}}/{{totalEchellesIQs}})
                </div>   
                
            </div>

            <div class="row">
                <div class="col-2 titre">
                    Poids score financier
                </div>
                <div class="col-4">
                    {{this.currentClient.poidsScoreFinancier}}%
                </div>
                <div class="col-2 titre">
                    Poids score qualitatif                     
                </div>
                <div class="col-4">
                     {{this.currentClient.poidsScoreQualitatif}}%
                </div>
            </div>

        </div>

<div class="flex align-content-end flex-wrap" >
                    <div class="flex align-items-center justify-content-center" style="width: 100%;">

        <!-- Score final et recommandations -->
        <div class="divPanel" style="padding:0 !important;width: 100%;">
            <div class="d-flex bd-highlight">
                <div class="p-0 flex-grow-1 bd-highlight" >
                    <!--  -->
                    <div class="flex align-content-start flex-wrap" style="height:100%">
                    <div class="flex align-items-center justify-content-center" style="padding:10px">
                    <div class="row" v-if="historiqueScoreActuel.id">
                        <div class="tabStribHeader" v-if="canSeeRecommandation">
                            <span class="pi pi-star" style="font-size:20px; margin-top:-4px"></span>
                            Score final et recommandations&nbsp; 
                        </div> 
                        <div class="col-3" v-if="canSeeRecommandation">
                            <span class="font-weight-bold titre" style="font-weight: bold;">Nature du risque</span>
                        </div> 
                        <div class="col-9" v-if="canSeeRecommandation">
                            {{recommandation.natureRisque}}
                        </div> 
                        
                        <div class="col-3 " v-if="canSeeRecommandation">
                            <span class="font-weight-bold titre" style="font-weight: bold;">Recommandation</span>
                        </div> 
                        <div class="col-9" v-if="canSeeRecommandation">
                            {{recommandation.recommandationAnalyse}}
                        </div>

                        <div class="col-3 " :style="canSeeRecommandation?'':'width:100%'" v-if="historiqueScoreActuel.etatPublication || (limiteCreditSouhaitee && selectedBilans.length==2 && (flagCreerFinancier || (currentClient.activerChoixLimiteCreditMinimum)))">
                            <span class="font-weight-bold titre">Limite de crédit recommandée</span>
                         </div> 
                        <div class="col-9" v-if="historiqueScoreActuel.etatPublication || (limiteCreditSouhaitee && selectedBilans.length==2 && (flagCreerFinancier || (currentClient.activerChoixLimiteCreditMinimum)))">
                            {{formatDecimal0(arrondiDixMilles(LimiteCreditRecommandee))}}
                        </div> 
                    <!--  -->
                    </div>
                    </div>
                    </div> 
                </div> 
                <div class="flex align-content-end flex-wrap" >
                    <div class="flex align-items-center justify-content-center" style="writing-mode: vertical-lr; padding:10px">
                        <P-Button @click="fnSaveScore" icon="pi pi-save" :label="historiqueScoreActuel.id?'Sauvegarder':'Créer'" class="p-button p-button-primary mr-auto" style="width:160px;" :disabled="(historiqueScoreActuel && historiqueScoreActuel.etatPublication) || (!areIQsEntered) || (!currentUser.jsonUserRights.scoringFinancier && !currentUser.jsonUserRights.scoringQualitatif && !currentUser.jsonUserRights.scoringPublication)"/>
                        <P-Button v-if="historiqueScoreActuel.etatPublication" @click="scorePdf" icon="pi pi-print" label="Imprimer" class="p-button-outlined p-button-danger mr-auto"  style="width:160px;margin-top:8px; margin-right:5px" :disabled="!historiqueScoreActuel.id" />
                        <P-Button @click="$router.push('/client-societes')" icon="pi pi-times" label="Quitter" class="p-button-outlined p-button-secondary mr-auto"  style="width:160px;margin-top:8px; margin-right:5px" />
                    </div>
                </div>
                <!-- ----{{scoreFinale}}-----    -->
                <div class="p-0 bd-highlight">
                    <!--  -->
                    <div class="col-2 finalScore align-middle"  style="padding:0px !important; position:relative;height: 100%;">  
                    <div style="text-align:center;">
                        <div style="padding:2px;font-size:20px; top:0px;right:0px;color:#359df3">Score</div> 
                        <Knob   v-model="scoreFinaleInt" 
                                    valueTemplate="{value}/10" 
                                    :min="0" :max="10"  
                                    :size="100"
                                    :valueColor="valueColor" 
                                    rangeColor="rgb(231, 229, 229)"
                                    textColor="#359df3"
                        ></Knob>
                            
                        <span v-if="this.historiqueScoreActuel.id" flow="left" :tooltip='publicationTooltip'  >
                            <P-Button  v-if="publicationTooltip == ''" icon="pi pi-check" label="Publier" class="p-button p-button-success mr-auto" style="width:160px;margin-top:4px;margin-bottom:4px;"  @click="PublierScore"/> 
                            <span v-else-if="!historiqueScoreActuel.etatPublication" style="color:red;font-weight:bold;text-align:center;font-size:17px">Non publié</span>
                        </span>
                            
                            
                        </div>
                    </div>
                    <!--  -->
                </div>
            </div>
            

        </div>
    </div>
</div>


        <div class="errorMessage" ref="errorMessage"> {{errorMessage}}</div>
    

        <!-- Modal to change date de validite -->
        
        <!--  -->

        <Dialog    ref="refChangeDateValidite"  class="p-fluid"
                   v-model:visible="flagOpenModifyDateValidateWithoutPublish" :style="{width: '500px'}" :header="'Modification date de validité score'" :modal="true">

                    <div class="p-field">
                        <label for="dateValidite"><b>Date de validité</b></label>
                        <PCalendar name="dateValidite"
                                :modelValue="this.formatDate(dernierHistoriqueScore.dateValidite)" 
                                v-model="dernierHistoriqueScore.dateValidite" 
                                :showIcon="true"
                                dateFormat="dd/mm/yyyy" 
                                :showButtonBar="false"
                                :yearNavigator="true"
                                yearRange="2000:2030"
                        />
                    </div>
                    <br/>
                    

                    <div>{{errorMessage}}</div>

                    <br/>
                    <div class="p-d-flex p-jc-end">
                   
                        <button class="btn btn-danger btn-block"  @click="fnClose">
                            Annuler
                        </button> &nbsp;
                        <button class="btn btn-primary btn-block" @click="fnUpdateDateValidite">
                            Enregistrer
                        </button>
                    </div>
        </Dialog>


        <!-- Modal to change date de validite -->
        <Dialog    ref="refChangeDateValidite"  class="p-fluid"
                   v-model:visible="flagOpenModifyDateValidite" :style="{width: '500px'}" :header="'Publication'" :modal="true">

                    <div class="p-field">
                        <label for="dateValidite"><b>Date de validité</b></label>
                        <PCalendar name="dateValidite"
                                :modelValue="this.formatDate(dernierHistoriqueScore.dateValidite)" 
                                v-model="dernierHistoriqueScore.dateValidite" 
                                :showIcon="true"
                                dateFormat="dd/mm/yyyy" 
                                :showButtonBar="false"
                                :yearNavigator="true"
                                yearRange="2000:2030"
                        />
                    </div>
                    <br/>
                    <span v-if="currentClient.activerChoixLimiteCreditMinimum && LimiteCreditRecommandee == 0" for="choixMinimumLimite"><b>Choix limite de crédit</b></span>
                    <span v-else for="choixMinimumLimite"><b>Limite de crédit</b> <br/> {{formatDecimal0(arrondiDixMilles(LimiteCreditRecommandee))}}</span>
                    
                    <br/>
                    <div class="p-field" v-if="currentClient.activerChoixLimiteCreditMinimum && LimiteCreditRecommandee == 0">
                        <RadioButton v-model="limiteCreditRecommandeeChoix" :value="currentClient.limiteCreditMinimum" />
                        <label for="limiteCreditMinimum" class="ml-2">{{formatDecimal0(arrondiDixMilles(currentClient.limiteCreditMinimum))}}</label>
                        &nbsp;
                        <RadioButton v-model="limiteCreditRecommandeeChoix" :value="0" />
                        <label for="limiteCreditMinimum" class="ml-2">0</label>
                    </div>
                    

                    <div>{{errorMessage}}</div>

                    <br/>
                    <div class="p-d-flex p-jc-end">
                   
                        <button class="btn btn-danger btn-block"  @click="fnClose">
                            Annuler
                        </button> &nbsp;
                        <button class="btn btn-primary btn-block" @click="Publish">
                            Publier
                        </button>
                    </div>
        </Dialog>
    
    </div>

    
</template>
<!-- ########################## <Script> ########################## -->
<script>
import Header from "../Header.vue";
import ClientScoringIF1Component from "./ClientScoringIF1Component.vue";
import ClientScoringIQ1Component from "./ClientScoringIQ1Component.vue";
import ClientBilanDialog from "./ClientBilanDialog.vue";
import ClientHistoriqueScoreDialog from "./ClientHistoriqueScoreDialog.vue";
// import CRUDService from "../1-common-components/crud.service";
import {_} from 'vue-underscore';
import { DropDownList } from '@progress/kendo-dropdowns-vue-wrapper';
import { Button } from '@progress/kendo-buttons-vue-wrapper';
import { TabStrip } from '@progress/kendo-layout-vue-wrapper';
import CRUDService from "../../1-common-components/crud.service";
import { DateInput } from '@progress/kendo-dateinputs-vue-wrapper';
import { mapGetters } from 'vuex';
import LoadPdfScore from "../LoadPdfScore.vue";
import RadioButton from 'primevue/radiobutton';

import { Field, Form, ErrorMessage, } from 'vee-validate';
import * as yup from 'yup';
import Knob from 'primevue/knob';

import SplitButton from 'primevue/splitbutton';

import BlockUI from 'primevue/blockui';

export default {
    name: "ClientScoring",
    //--------------------------- Components --------------------------
    components: {
        Header, 
        ClientScoringIF1Component,
        ClientScoringIQ1Component,
        'kendo-dropdownlist': DropDownList,
        'kendo-button': Button,
        'kendo-tabstrip': TabStrip,
        'kendo-dateinput': DateInput,
        'V-Field':Field,
        'V-Form':Form,
        ErrorMessage,
        Knob,
        'LoadPdfScore' : LoadPdfScore,
        ClientBilanDialog,
        ClientHistoriqueScoreDialog,
        SplitButton,
        BlockUI,
        RadioButton
    },
    //---------------------------- props ------------------------------
    props:   ['societeId','scoreId'],
    //------------------------------ Data -----------------------------
    data() {
        return {
            limiteCreditRecommandeeChoix:0,
            limiteCreditSouhaitee:0,
            selectedSociete: {id:0, raisonSociale: 0, ice:0, rc:0, dernierScore:0, utilisateur:"NA"},
            errorMessage : "",
            bilansArray: [],
            dernierHistoriqueScore: {},
            flagOpenModifyDateValidite: false,
            showBilanDialogFlag: false,
            showHistoriqueScoreDialogFlag : false,
            selectedBilanItem: {id:0},
            selectedGridInitialState: {id:0},
            selectedBilans: [],
            flagBilanChanged:0,
            flagCreerNouveauScore: false,
            caEscompte:0,
            delaiPaiement:0,
            historiqueScoreActuel:{},
            inforisk:false,
            flagCreerFinancier:false,
            flagCreerQualitatif:false,
            flagQualitatifChanged:0,
            flagOpenModifyDateValidateWithoutPublish:false,
            items: [
                {
                    label: 'Manuel',
                    icon: 'pi pi-pencil',
                    command: () => {
                        this.openEditBilanDialog({id:0,societeId:this.selectedSociete.id, dateBilan:new Date()}, false)
                    }
                },
                {
                    label: 'Inforisk',
                    icon: 'pi pi-cloud-download',
                    command: () => {
                        this.openEditBilanDialog({id:0,societeId:this.selectedSociete.id, dateBilan:new Date()}, true)
                    }
                }
                ],
        }
    }, //end data()
    watch:
    {
        societeId:function()
        {
            this.loadScoring()
        },
        scoreId:function()
        {
            this.loadScoring()
        }
        
    },
    computed: {
        ...mapGetters("clientScoringStoreModule",[
          'totalEchellesIFs',
          'totalEchellesIQs'
        ]),
        canSeeRecommandation()
        {
            return this.historiqueScoreActuel.etatPublication && (this.currentUser.jsonUserRights.scoringFinancier || this.currentUser.jsonUserRights.scoringQualitatif)   
        },
        publicationTooltip()
        {
            
            if(!this.historiqueScoreActuel.id) {
                return "Non créé"
            } else if(this.historiqueScoreActuel.etatPublication) {
                return "Score publié"
            } else if(!this.currentClient.activerWorkflowValidation && (!this.areIQsEntered) ) {
                return "Publication nécessite un score qualitatif complet"
            } else if(this.currentClient.activerWorkflowValidation && (!this.historiqueScoreActuel.etatValidationQualitative || this.historiqueScoreActuel.etatValidationQualitative<2) ) {
                return "Publication nécessite la validation du score qualitatif"
            } else if(!this.currentUser.jsonUserRights.scoringPublication) {
                return "Vous n'avez pas les droits pour publier le score"
            }
            else if(!this.flagCreerQualitatif)
            {
                return "Veuillez créer le score qualitatif"
            }
            else
            {
                // Il faut retourner vide quand tout les tests de publication ont été validé
                return ""
            }
        },
        fetchedIndicateurQualitatif()
        {
            // return null
            if(this.historiqueScoreActuel && this.historiqueScoreActuel.jsonQualitatif)
            {
                return JSON.parse(this.historiqueScoreActuel.jsonQualitatif)
            }
            else
            {
                return null
            }
        },
        fetchedIndicateurFinancier()
        {
            // return null
            if(this.historiqueScoreActuel && this.historiqueScoreActuel.jsonFinancier)
            {
                return JSON.parse(this.historiqueScoreActuel.jsonFinancier)
            }
            else
            {
                return null
            }
        },
        nouveauScoreButtonLabel()
        {
          return "Nouveau score";
        },
        LimiteCreditCalculee(){
            if(isNaN(this.delaiPaiement) || isNaN(this.caEscompte)) {
                return 0;
            }

            var valeur = Math.round(this.caEscompte / 360 * this.delaiPaiement);
            // this.limiteCreditSouhaitee = valeur;
            return valeur
        },
        LimiteCreditRecommandee()
        {
            if(this.flagCreerFinancier && this.flagCreerQualitatif)
            {
                return Math.max(Math.min(this.limiteCreditSouhaitee,this.limiteCreditSelonScore),this.montantLimiteQualitatif);
            }
            else if(!this.flagCreerFinancier)
            {
                return this.montantLimiteQualitatif
            }
            
        },
        VNC()
        {
            
            if(this.bilansJson && this.bilansJson.N && this.bilansJson.N.actifTotalIImmobiliseTotal)
            {
                //Total Actif NET - ( Dettes du passif circulant + dettes financières )
                return (this.bilansJson.N.actifTotalIImmobiliseTotal) - 
                ((this.bilansJson.N.passifFCirculantDettesDuCirculant?this.bilansJson.N.passifFCirculantDettesDuCirculant:0)
                +(this.bilansJson.N.passifCFinancementPermanentDettesDeFinancement?this.bilansJson.N.passifCFinancementPermanentDettesDeFinancement:0))
            }
            else
            {
                return 0
            }
        },
        recommandation(){
            var score = this.scoreFinale;
 
            var targetRecommandation = {natureRisque:"NA", recommandationAnalyse:"NA"};
            for (let index = 0; index < this.recommandations.length; index++) {
                const element = this.recommandations[index];
                var min = element.scoreMin;
                var max = element.scoreMax;
                // For the last one, we add epsilon to the max to be <=
                if(index+1 == this.recommandations.length) {
                    max = max + Number.EPSILON;
                }

                if(score >=  min && score < max)
                {
                    targetRecommandation = element;
                }
            }
            return targetRecommandation;
        },
        //Calcule la limite de crédit en multipliant la Vnc par le pourcentage selon le score
        limiteCreditSelonScore(){
            var score = this.scoreFinale;
 
            var pourcentVnc = 0;
            var montantLimiteQualitatif = 0
            for (let index = 0; index < this.limitecredits.length; index++) {
                const element = this.limitecredits[index];
                var min = element.scoreMin;
                var max = element.scoreMax;
                if(index+1 == this.limitecredits.length) {
                    max = max + Number.EPSILON;
                }

                if(score >=  min && score < max)
                {
                    pourcentVnc = element.pourcentLimiteCredit;
                    montantLimiteQualitatif = element.montantLimiteQualitatif
                }
            }
            return this.VNC * (pourcentVnc/100);
        },
        montantLimiteQualitatif(){
            var score = this.scoreFinale;
 
            for (let index = 0; index < this.limitecredits.length; index++) {
                const element = this.limitecredits[index];
                var min = element.scoreMin;
                var max = element.scoreMax;

                if(score >=  min && score < max)
                {
                    return element.montantLimiteQualitatif?element.montantLimiteQualitatif:0
                }
            }
            return 0
        },
        flagSavedNouveauScore(){
            return this.$store.state.clientScoringStoreModule.flagSavedNouveauScore;
        },
        recommandations(){
            return this.$store.state.clientScoringStoreModule.recommandations;
        },
        limitecredits(){
            return this.$store.state.clientScoringStoreModule.limiteCredits;
        },
        valueColor()
        {
            var couleur = this.recommandation.couleur;

            return (couleur)?couleur:"black";
        },
        scoreFinaleInt(){  
            var score = Math.floor(this.scoreFinale);
            return isNaN(score)?0:score;
        },
        scoreFinale(){  
            return ((this.noteIF * this.currentClient.poidsScoreFinancier / 100) + (this.noteIQ * this.currentClient.poidsScoreQualitatif / 100)) / 10 ;
        },
        noteIF(){  
            
            return this.getValidNumberOrReturnZero(this.sommeNotesIFs / this.totalEchellesIFs * 100);
        },
        noteIQ(){
            return this.getValidNumberOrReturnZero(this.sommeNotesIQs / this.totalEchellesIQs * 100);
        },  
        sommeNotesIFs() {
            var sommeNotes = 0;
            if(this.validBilansSelection == false)
            {
                return 0;
            }

            for (let index = 0; index < this.IFsList.length; index++) {
                const element = this.IFsList[index];
                sommeNotes += element.note;
            }
            return sommeNotes;
        },
        sommeNotesIQs() {
            var sommeNotes = 0;
            for (let index = 0; index < this.IQsList.length; index++) {
                const element = this.IQsList[index];
                sommeNotes += element.note;
            }
            return sommeNotes;
        },
        areIQsEntered() {
            var result = true;
            // 
            for (let index = 0; index < this.IQsList.length; index++) {
                const element = this.IQsList[index];
                console.log("element.selected=================:"+element.selected);
                if(!element.selected) {
                    result = false;
                }
            }
            return result;
        },
        IQsList(){
            return this.$store.state.clientScoringStoreModule.IQsList;
        },
        IFsList(){
            return this.$store.state.clientScoringStoreModule.IFsList;
        },
        currentClient()  {
            return this.$store.state.clientScoringStoreModule.currentClient;
        },
        dateDernierBilan(){
            if(this.bilansJson.N){
                return this.formatDate(this.bilansJson.N.dateBilan);
            }
            return "NA";
        },
        bilansJson()
        {
            var bilansJson = {};
            
            if(this.selectedBilans.length >= 1){
                bilansJson.N = this.selectedBilans[0];
            }
            if(this.selectedBilans.length >= 2){
                bilansJson.N1 = this.selectedBilans[1];
            }

            return  bilansJson;
        },
        validBilansSelection()
        {
            if(this.selectedBilans.length != 2) {
                return false;
            }
            else {
                var selectionDistance = this.selectedBilans[1].index - this.selectedBilans[0].index;
                if( selectionDistance != 1 && selectionDistance != -1 ) {
                    return false;
                }
            }

            return true;
            
        },
        usedBilanYears()
        {
            ;
            var bilanN = this.bilansJson.N?this.bilansJson.N.dateBilan:"";
            var bilanN1 = this.bilansJson.N1?this.bilansJson.N1.dateBilan:"";
            if(bilanN == "" || bilanN1 == "")
            {
                return "";
            }
            return bilanN + "-" + bilanN1;
        },

        bilanNAnnee()
        {
            var anneeN = this.bilansJson.N?this.bilansJson.N.dateBilan:"";
            return anneeN;
        },

        bilanNJsonInfo()
        {
            var bilanN = this.bilansJson.N?this.bilansJson.N:"";
            if(bilanN == "")
            {
                return "";
            }

            var bilanJsonObj = {
                dateModification:bilanN.dateModification?bilanN.dateModification:bilanN.dateCreation,
                modificateur:bilanN.modificateur?bilanN.modificateur:bilanN.createur,
                EstInforisk:bilanN.inforisk?true:false,
                estAnneePrecedente:bilanN.estAnneePrecedente?true:false
            }
            return JSON.stringify(bilanJsonObj);
        },

        bilanN1Annee()
        {
            var anneeN1 = this.bilansJson.N1?this.bilansJson.N1.dateBilan:"";
            return anneeN1;
        },

        bilanN1JsonInfo()
        {
            var bilanN1 = this.bilansJson.N1?this.bilansJson.N1:"";
            if(bilanN1 == "")
            {
                return "";
            }

            var bilanJson = {
                dateModification:bilanN1.dateModification?bilanN1.dateModification:bilanN1.dateCreation,
                modificateur:bilanN1.modificateur?bilanN1.modificateur:bilanN1.createur,
                EstInforisk:bilanN1.inforisk?true:false,
                estAnneePrecedente:bilanN1.estAnneePrecedente?true:false
            }
            return JSON.stringify(bilanJson);
        },
        
    },
    //------------------------------ Mounted ---------------------------
    mounted() {
        this.loadScoring()
         
    }, //end mounted
    //------------------------------- Created --------------------------
    created() {
        this.log("----------- created()   => ClientScoring");
        this.fnSetFlagSavedNouveauScore(false);
        this.$store.commit('clientScoringStoreModule/reset');
    },
    //-------------------------------- Methods --------------------------
    methods: {
        
        async GenerateCreerFinancier()
        {
            this.flagCreerFinancier = true;
            this.flagBilanChanged++;
            await this.fnLoadBilans();
            this.onBilanCheckboxChange({})
        },
        async loadScoring()
        {
            this.log("----------- mounted() => ClientScoring");

            this.caEscompte=0
            this.delaiPaiement=0
            this.limiteCreditSouhaitee=0

            if(!this.scoreId || this.scoreId=='0')
            {
                this.fnLoadCurrentClient();
                this.fnLoadDernierHistoriqueScore();
                this.fnLoadBilans();
                this.fnLoadRecommandations();
                this.fnLoadLimiteCredits();
                this.fnLoadSociete();

                this.fnInitFlagScoreEtIndicateurs()
            }
            else
            {
                this.mountedWithScore()
            } 
        },
        async SoumettreFinancier()
        {
            if(!this.areIQsEntered && this.flagCreerQualitatif)
            {
                this.toastWarn("Veuillez renseigner les champs qualitatifs")
                this.historiqueScoreActuel.etatValidationQualitative = 0
                return;
            }
            this.historiqueScoreActuel.etatValidationFinanciere = 1;
            this.fnSaveScore()
        },
        async ValiderFinancier()
        {
            if(!this.areIQsEntered && this.flagCreerQualitatif)
            {
                this.toastWarn("Veuillez renseigner les champs qualitatifs")
                this.historiqueScoreActuel.etatValidationQualitative = 0
                return;
            }
            this.historiqueScoreActuel.etatValidationFinanciere = 2;
            this.fnSaveScore()
        },
        async SoumettreQualitatif()
        {
            if(!this.areIQsEntered && this.flagCreerQualitatif)
            {
                this.toastWarn("Veuillez renseigner les champs qualitatifs")
                this.historiqueScoreActuel.etatValidationQualitative = 0
                return;
            }
            this.historiqueScoreActuel.etatValidationQualitative = 1;
            this.fnSaveScore()
        },
        async ValiderQualitatif()
        {
            if(!this.areIQsEntered && this.flagCreerQualitatif)
            {
                this.toastWarn("Veuillez renseigner les champs qualitatifs")
                this.historiqueScoreActuel.etatValidationQualitative = 0
                return;
            }
            this.historiqueScoreActuel.etatValidationQualitative = 2;
            this.fnSaveScore()
        },
        async PublierScore()
        {
            if(!this.areIQsEntered && this.flagCreerQualitatif)
            {
                this.toastWarn("Veuillez renseigner les champs qualitatifs")
                this.historiqueScoreActuel.etatValidationQualitative = 0
                return;
            }
            // this.historiqueScoreActuel.etatPublication = 1;
            //await this.fnSaveScore()
            this.flagOpenModifyDateValidite  = true;
        },
        async mountedWithScore()
        {
            await this.fnLoadCurrentClient();
            await this.fnLoadDernierHistoriqueScore();
            await this.fnLoadBilans();
            await this.fnLoadRecommandations();
            await this.fnLoadLimiteCredits();
            await this.fnLoadSociete();
            
            var response = await CRUDService.get(this.scoreId, 'historiquescoreclient')
            if(response.data)
            {
                this.historiqueScoreActuel = response.data

                this.caEscompte=this.historiqueScoreActuel.caEscompte
                this.delaiPaiement=this.historiqueScoreActuel.delaiPaiement
                this.limiteCreditSouhaitee=this.historiqueScoreActuel.limiteCreditSouhaitee

                this.flagCreerNouveauScore = true

                this.fnSetFlagSavedNouveauScore(true);

                if(this.historiqueScoreActuel.jsonFinancier)
                {
                    this.flagCreerFinancier = true
                    this.flagBilanChanged++
                }

                if(this.historiqueScoreActuel.jsonQualitatif)
                {
                    this.flagCreerQualitatif = true
                }
                
                
                this.$store.commit('clientScoringStoreModule/setScoreActuel',this.historiqueScoreActuel);
                this.selectBilanFromHistoriqueClient()
                
                this.fnInitFlagScoreEtIndicateurs()

                this.flagQualitatifChanged++
            }
            else
            {
                alert('Erreur Get Score')
            }
        },
        fnSetFlagSavedNouveauScore(value){
            this.$store.commit('clientScoringStoreModule/setFlagSavedNouveauScore',value);
        },
        fnInitFlagScoreEtIndicateurs()
        {
            console.log("fnInitFlagScoreEtIndicateurs");
            if(this.flagCreerNouveauScore == true)
            {
                this.$store.commit('clientScoringStoreModule/resetIndicateurs');
                this.$router.go();
            }

            this.flagCreerNouveauScore = true;
            this.fnSetFlagSavedNouveauScore(false);

            return;

            if(this.limiteCreditSouhaitee==0 && (!this.historiqueScoreActuel || this.historiqueScoreActuel.id==0))
            {
                this.$confirm.require({
                    message: 'La limite de crédit n\'est pas valide. Voulez-vous continuer?',
                    header: 'Confirmation',
                    icon: 'pi pi-exclamation-triangle',
                    acceptLabel:'Oui',
                    rejectLabel:'Non',
                    accept: () => {
                        this.flagCreerNouveauScore = true;
                        this.fnSetFlagSavedNouveauScore(false);
                    },
                    // reject: () => {
                    //     //callback to execute when user rejects the action
                    // }
                });
            }
            else
            {
                this.flagCreerNouveauScore = true;
                this.fnSetFlagSavedNouveauScore(false);
            }
            
        },
        selectBilanFromHistoriqueClient()
        {
            var ref = this;
            this.selectedBilans = this.bilansArray.filter(function(b){
                return b.dateBilan == ref.historiqueScoreActuel.bilanNAnnee || b.dateBilan == ref.historiqueScoreActuel.bilanN1Annee
            })
            
            
            
            this.$store.commit('clientScoringStoreModule/resetIndicateurs');
            console.log("selectBilanByYear");
            console.log(this.selectedBilans);
            if(this.selectedBilans.length > 2){
                this.selectedBilans.shift(); //remove the 1st selection (shift) when total selection exceeds 2
            }
            console.log("Update bilansJson in store");
            this.$store.commit('clientScoringStoreModule/setBilanJson', this.bilansJson);
            this.$store.commit('clientScoringStoreModule/setValidBilansSelection', this.validBilansSelection);

            this.flagCreerNouveauScore = false;
            this.fnSetFlagSavedNouveauScore(false);
        },
        onBilanCheckboxChange(event)
        {
            // this.$store.commit('clientScoringStoreModule/resetIndicateurs');
            console.log("onBilanCheckboxChange");
            console.log(event);
            console.log(this.selectedBilans);
            if(this.selectedBilans.length > 2){
                this.selectedBilans.shift(); //remove the 1st selection (shift) when total selection exceeds 2
            }

            if(!this.selectedBilans[0] || !this.selectedBilans[1])
            {
                return;
            }

            var Ndate = parseInt(this.selectedBilans[0].dateBilan)
            var N1date = parseInt(this.selectedBilans[1].dateBilan)
            // 

            if(Ndate < N1date)
            {
                var temp = this.selectedBilans[1]
                this.selectedBilans[1] = this.selectedBilans[0];
                this.selectedBilans[0] = temp;
            }
            
            console.log("Update bilansJson in store");
            this.$store.commit('clientScoringStoreModule/setBilanJson', this.bilansJson);
            this.$store.commit('clientScoringStoreModule/setValidBilansSelection', this.validBilansSelection);

            if(this.selectedBilans.length < 2 && this.flagCreerFinancier){
                this.flagCreerNouveauScore = false;
                this.fnSetFlagSavedNouveauScore(false);
            }
            else
            {
                this.flagCreerNouveauScore = true;
                this.fnSetFlagSavedNouveauScore(true);
            }
        },
        async Publish()
        {
            this.log("Publish");

            this.dernierHistoriqueScore.dateValidite = this.getUtcDate(this.dernierHistoriqueScore.dateValidite);
            this.log(this.dernierHistoriqueScore.dateValidite);

            this.historiqueScoreActuel.etatPublication = 1;

            await this.fnSaveScore()
            

            var endpoint = "historiquescoreclient/updatedatevalidite";
            CRUDService.update(this.dernierHistoriqueScore, endpoint).then(
                (response) => {
                    this.log("----------------- Publish reponse");
                    this.log(response.data);
                    this.flagOpenModifyDateValidite = false;
                    this.$toast.add({ severity:'success', summary: 'Operation réussie', detail:'Date de validité modifiée', life: 3000});
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
            
        },
        async fnUpdateDateValidite()
        {
            this.log("Publish");

            this.dernierHistoriqueScore.dateValidite = this.getUtcDate(this.dernierHistoriqueScore.dateValidite);
            this.log(this.dernierHistoriqueScore.dateValidite);
            

            var endpoint = "historiquescoreclient/updatedatevalidite";
            CRUDService.update(this.dernierHistoriqueScore, endpoint).then(
                (response) => {
                    this.log("----------------- Publish reponse");
                    this.log(response.data);
                    this.flagOpenModifyDateValidite = false;
                    this.$toast.add({ severity:'success', summary: 'Operation réussie', detail:'Date de validité modifiée', life: 3000});
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
            
        },
        fnClose(){
            this.flagOpenModifyDateValidite = false;
        },
        fnSaveScore()
        {
            if(this.flagCreerFinancier && !this.limiteCreditSouhaitee)
            {
                this.toastWarn("La limite de crédit souhaitée est vide, veuillez saisir un montant")
                return;
            }
            
            if(!this.areIQsEntered && this.flagCreerQualitatif)
            {
                this.toastWarn("Veuillez renseigner les champs qualitatifs")
                this.historiqueScoreActuel.etatValidationQualitative = 0
                return;
            }
            this.log("fnSaveScore");
            var endpoint = "historiquescoreclient";
            var ThreeMFromNow = new Date();
            ThreeMFromNow.setMonth(ThreeMFromNow.getMonth() + 3);
            
            var jsonFinancier =null

            if(this.validBilansSelection && this.flagCreerFinancier)
            {
                jsonFinancier = this.IFsList.map(function(d){
                    return {id:d.id,note:d.note,formule:d.formule,valeur:d.valeur}
                })
            }

            var jsonQualitatif = null

            if(this.flagCreerQualitatif)
            {
                jsonQualitatif = this.IQsList.map(function(d){
                    var foundItem = d.listeChoix.find(function(f){
                                return d.listeChoixId == f.id
                            })

                    return {
                        id:d.id,
                        note:d.note,
                        listeChoixId:foundItem?foundItem.id:null
                    }
                })
            }

            
            //si limitecreditminimum est desactivé 
            if(!this.currentClient.activerChoixLimiteCreditMinimum || this.LimiteCreditRecommandee != 0)
            {
                this.limiteCreditRecommandeeChoix = this.LimiteCreditRecommandee
            }

            var historiqueScoreObj = {
                        "scoreFinancier": this.noteIF,
                        "scoreQualitatif": this.noteIQ,
                        "poidsScoreFinancier": this.currentClient.poidsScoreFinancier,
                        "poidsScoreQualitatif": this.currentClient.poidsScoreQualitatif,
                        "score": this.scoreFinaleInt,
                        "dateScore": new Date(),
                        "dateValidite": ThreeMFromNow,
                        "natureRisque": this.recommandation.natureRisque,
                        "recommandation": this.recommandation.recommandationAnalyse,
                        "utilisateur": this.currentUser.prenom + " " + this.currentUser.nom,
                        "societeId": this.selectedSociete.id,
                        "caEscompte":this.caEscompte,
                        "delaiPaiement":this.delaiPaiement,
                        "kpiColor":this.valueColor,
                        // "derniersBilans": this.usedBilanYears,
                        "bilanNAnnee":this.flagCreerFinancier?this.bilanNAnnee:'',
                        "bilanN1Annee":this.flagCreerFinancier?this.bilanN1Annee:'',
                        "bilanNJsonInfo":this.flagCreerFinancier?this.bilanNJsonInfo:'',
                        "bilanN1JsonInfo":this.flagCreerFinancier?this.bilanN1JsonInfo:'',
                        "limiteCreditRecommandee":this.limiteCreditRecommandeeChoix,
                        "limiteCreditSouhaitee":this.limiteCreditSouhaitee,
                        "limiteCreditSelonScore":this.limiteCreditSelonScore,
                        "vnc":this.VNC,
                        "jsonFinancier":jsonFinancier?JSON.stringify(jsonFinancier):null,
                        "jsonQualitatif":jsonQualitatif?JSON.stringify(jsonQualitatif):null,
                        
                    };
            
                    if(this.scoreId && this.historiqueScoreActuel.id)
                    {
                        historiqueScoreObj['id'] = this.historiqueScoreActuel.id

                        historiqueScoreObj['etatValidationQualitative'] = this.historiqueScoreActuel.etatValidationQualitative??0
                        historiqueScoreObj['etatValidationFinanciere'] = this.historiqueScoreActuel.etatValidationFinanciere??0
                        historiqueScoreObj['etatPublication'] = this.historiqueScoreActuel.etatPublication??0

                        return CRUDService.update(historiqueScoreObj, endpoint).then(
                            (response) => {
                                this.log("----------------- fnLoadDernierHistoriqueScore reponse");
                                this.log(response.data);
                                this.dernierHistoriqueScore = response.data;
                                this.toastSuccess('Score a été enregistré');
                                this.fnSetFlagSavedNouveauScore(true);

                                
                            },
                            (error) => {
                                this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                                this.toastError('Echec d\'enregistrement du score !');
                            }
                        );

                        return;
                    }
            
            // 
            return CRUDService.create(historiqueScoreObj, endpoint).then(
                (response) => {
                    this.log("----------------- fnLoadDernierHistoriqueScore reponse");
                    this.log(response.data);
                    this.dernierHistoriqueScore = response.data;
                    this.toastSuccess('Score a été créé');
                    this.fnSetFlagSavedNouveauScore(true);

                    // this.flagOpenModifyDateValidite  = true;
                    this.$router.push("/client-scoring/"+this.societeId+"/"+this.dernierHistoriqueScore.id)
                    // this.historiqueScoreActuel = response.data
                    // this.fnSetFlagSavedNouveauScore(true);
                    // this.flagCreerNouveauScore = true
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    this.toastError('Echec d\'enregistrement du score !');
                }
            );
        },
        fnLoadDernierHistoriqueScore()
        {
            var endpoint = "dernierehistoriquescorebysociete";
            
            CRUDService.get(this.societeId, endpoint).then(
                (response) => {
                    this.log("----------------- fnLoadDernierHistoriqueScore reponse");
                    this.log(response.data);
                    this.dernierHistoriqueScore = response.data;
                    // this.caEscompte = response.data.caEscompte;
                    // this.delaiPaiement = response.data.delaiPaiement;
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
        },
        fnLoadBilans()
        {
            var endpoint = "bilanfinancierbysociete";
            return CRUDService.get(this.societeId, endpoint).then(
                (response) => {
                    this.log("----------------- fnLoadBilans reponse");
                    this.log(response.data);

                    for (let index = 0; index < response.data.length; index++) {
                        const element = response.data[index];
                        element.index = index;
                    }
                    
                    this.bilansArray = response.data;

                    //preset the first two bilan
                    // if(this.bilansArray.length >=2) {
                    //     this.selectedBilans = this.bilansArray.slice(0,2); 
                    // }
                    // else if(this.bilansArray.length ==1) {
                    //     this.selectedBilans = this.bilansArray.slice(0,1); 
                    // }

                    this.$store.commit('clientScoringStoreModule/setBilanJson', this.bilansJson);
                    
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
        },
        fnLoadSociete()
        {
            var endpoint = 'societiescustom';
            
            CRUDService.get(this.societeId, endpoint).then(
                (response) => {
                    this.log("----------------- fnLoadSociete reponse");
                    this.log(response.data);
                    this.selectedSociete = response.data;
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
        },
        fnLoadRecommandations()
        {
            var endpoint = "recommandationsbyclientid";
            
            CRUDService.get(0, endpoint).then(
                (response) => {
                    this.log("----------------- fnLoadRecommandations reponse");
                    this.log(response.data);
                    // this.recommandations = response.data;
                    this.$store.commit('clientScoringStoreModule/setRecommandations', response.data);
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
        },
        
        fnLoadLimiteCredits()
        {
            var endpoint = "limitecreditsbyclientid";
            
            CRUDService.get(0, endpoint).then(
                (response) => {
                    
                    this.log("----------------- fnLoadLimiteCredit reponse");
                    this.log(response.data);
                    this.$store.commit('clientScoringStoreModule/setLimiteCredits', response.data);
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
        },
        fnLoadCurrentClient()
        {
            var endpoint = "currentclientcustom";
            
            CRUDService.get(0, endpoint).then(
                (response) => {
                    this.log("----------------- fnLoadClient reponse");
                    this.log(response.data);
                    this.$store.commit('clientScoringStoreModule/setCurrentClient', response.data);
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
        },
        openEditBilanDialog(selectedItem,inforisk){
            ;
            this.log("openEditBilanDialog");
            this.log(selectedItem);
            this.showBilanDialogFlag = true;
            ;
            this.selectedBilanItem = selectedItem;
            if(selectedItem.inforisk)
            {
                this.inforisk = selectedItem.inforisk;
            }
            {
                this.inforisk = inforisk
            }
            
            this.selectedBilanItemInitialState = { ...this.selectedBilanItem }; //this is an object copy using decomposition
        },
        openEdit()
        {
            // ;
            if($('.p-tieredmenu').length == 0)
            {
                $("#ButtonClickedId_0 .p-splitbutton-menubutton").click()
                // this.selectedSociete = selectedItem
            }
            
        },
        openHistoriqueScoreDialog(){
            this.log("openHistoriqueScoreDialog");
            this.showHistoriqueScoreDialogFlag = true;
        },
        closeHistoriqueScoreDialog(){
            this.log("openHistoriqueScoreDialog");
            this.showHistoriqueScoreDialogFlag = false;
        },
        DialogBilanFalse()
        {
            this.showBilanDialogFlag = false;
        },
        closeBilanDialog(){
            this.log("closeBilanDialog"); 
            this.selectedBilanItem = this.selectedBilanItemInitialState;
            // this.selectedItem = this.selectedBilanItemInitialState;
            // this.fnLoadBilans()
            this.DialogBilanFalse()
        },
        savedBilanDialog(closeDialog){
            var ref=this
            this.log("savedBilanDialog"); 
            this.flagCreerNouveauScore = false;
            this.fnLoadBilans();
            this.flagBilanChanged++
            if(closeDialog)
            {
                this.$nextTick(function(){
                    ref.DialogBilanFalse()
                })
            }
            
        },
        scorePdf : function()
        {
            var id = this.dernierHistoriqueScore.id;
            this.$refs.LoadPdfScore.loadPdfFromSociete(id);
        },
        scorePdfById : function(id)
        {
            this.$refs.LoadPdfScore.loadPdfFromSociete(id);
        },
    
    }
    //-------------------------------------------------------------------
} //end export default
</script>

<!-- ############################### <Style> ############################ -->
<style>

.p-inputnumber, .p-inputnumber-input
{
    width: 180px;
}

.row {
  display: flex;
}

.align-middle
{
    display: flex;
    align-items: center;
    justify-content: center;
}
.column {
  flex: 50%;
}
.border-right
{
     border-right: 1px dashed #333;
}

.finalScore
{
    /* background-color: #1254cc; */
    /* background-color: #2196f3; */
    /* background-color: #359df3; */
    /* background-color: #359df3; */
    border-color:#359df3;
    border-style:none none none solid;
    border-width:1px;
    color:white;
    font-size: 25px;
    border-top-left-radius: 45px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    width: 200px;

}

.titre
{
    font-weight: bold;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
}

.p-splitbutton-menubutton
{
    visibility:hidden
}

.p-blockui
{
    border-radius:5px;
    background-color:#00000008 !important;
}

.scoringDiv .table>:not(caption)>*>* {
    padding: 0.0rem 0.0rem !important;
   
}
</style>